import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/projek1.jpg";
import projImg2 from "../assets/img/projek3.jpg";
import projImg3 from "../assets/img/projek2.jpg";
import projImg4 from "../assets/img/projek4.webp";
import projImg5 from "../assets/img/projek5.jpg";
import projImg6 from "../assets/img/projek6.jpg";
import projImg7 from "../assets/img/projek7.jpg";
import projImg8 from "../assets/img/projek8.jpg";
import projImg9 from "../assets/img/projek9.jpg";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

// Helper function to chunk an array
function chunkArray(array, size) {
  const chunked = [];
  let index = 0;
  while (index < array.length && chunked.length < 3) {
    // Limit the number of chunks to 3
    chunked.push(array.slice(index, size + index));
    index += size;
  }
  return chunked;
}

export const Projects = () => {
  const projects = [
    {
      title: "Food Management System",
      description:
        "Using full stack development, I created a food management system that allows users to add, delete, and update food items.",
      imgUrl: projImg1,
    },
    {
      title: "To-Do List",
      description:
        "I created a to-do list using Flutterflow and Firebase that allows users to add, delete, and update tasks.",
      imgUrl: projImg2,
    },
    {
      title: "Graph Projection using R",
      description:
        "I created a graph projection using R that process data and displays it in a graph format.",
      imgUrl: projImg3,
    },
    {
      title: "Face Recognition System",
      description:
        "I created a face recognition system using Python and OpenCV that detects faces and recognizes them.",
      imgUrl: projImg4,
    },
    {
      title: "Automatic Evaluation Extension",
      description:
        "I created an automatic evaluation extension using JavaScript that automatically ticks the checkboxes.",
      imgUrl: projImg5,
    },
    {
      title: "Tic Tac Toe Game",
      description:
        "I created a tic tac toe game using Wokwi and Arduino that allows users to play against the computer.",
      imgUrl: projImg6,
    },
    {
      title: "Toyyibpay Payment Gateway",
      description:
        "I created a payment gateway using Toyyibpay that allows users to make payments.",
      imgUrl: projImg7,
    },
    {
      title: "ASNB Profit Calculator",
      description:
        "I created a profit calculator using React that calculates the profit for ASNB.",
      imgUrl: projImg8,
    },
    {
      title: "Chess Game",
      description:
        "I created a chess game using JavaScript that allows users to play against each other.",
      imgUrl: projImg9,
    },
  ];

  // Divide projects into chunks of 2
  const projectChunks = chunkArray(projects, 3);

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div>
                  <h2>Projects</h2>
                  <p>
                    Here are some of the projects that I have worked on. Click
                    on the tabs to view more projects.
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="tab1">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      {projectChunks.map((_, index) => (
                        <Nav.Item key={index}>
                          <Nav.Link eventKey={`tab${index + 1}`}>
                            Tab {index + 1}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                    <Tab.Content>
                      {projectChunks.map((chunk, index) => (
                        <Tab.Pane eventKey={`tab${index + 1}`} key={index}>
                          <Row>
                            {chunk.map((project, index) => {
                              return <ProjectCard key={index} {...project} />;
                            })}
                          </Row>
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
