import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Services = () => {
  const services = [
    {
      icon: "bi bi-laptop", // Bootstrap icon for Web Development
      title: "Web Development",
      description:
        "Creating responsive and functional websites tailored to your needs.",
    },
    {
      icon: "bi bi-phone", // Bootstrap icon for Mobile App Development
      title: "Mobile App Development",
      description:
        "Building cross-platform mobile applications that enhance user engagement.",
    },
    {
      icon: "bi bi-hdd", // Bootstrap icon for Database Management
      title: "Database Management",
      description:
        "Designing and managing databases for optimal performance and security.",
    },
    {
      icon: "bi bi-house", // Bootstrap icon for Hosting Services
      title: "Website Hosting",
      description:
        "Reliable hosting solutions to get your website online with minimal downtime.",
    },
    {
      icon: "bi bi-tools", // Bootstrap icon for Arduino Development
      title: "Arduino Development",
      description:
        "Creating innovative projects using Arduino for various applications.",
    },
    {
      icon: "bi bi-person-check", // Bootstrap icon for Tutoring
      title: "Programming Tutoring",
      description:
        "Offering personalized tutoring sessions to help you master programming.",
    },
  ];

  return (
    <section
      className="services"
      id="services"
      style={{ padding: "60px 0" }} // Removed background color
    >
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div>
                  <h2
                    style={{
                      textAlign: "center",
                      color: "#fff", // Title color is white
                      fontSize: "2.5em",
                      marginBottom: "20px",
                    }}
                  >
                    Freelancing Services
                  </h2>
                  <p
                    style={{
                      textAlign: "center",
                      color: "#fff", // Description color is white
                      fontSize: "1.2em",
                      marginBottom: "40px",
                    }}
                  >
                    Here are some of the services I offer as a freelancer to
                    help bring your ideas to life.
                  </p>
                  <Row className="justify-content-center">
                    {services.map((service, index) => (
                      <Col md={4} key={index} className="mb-4">
                        <div
                          className={`service-item wow zoomIn ${
                            isVisible ? "animate__animated animate__fadeIn" : ""
                          }`}
                          style={{
                            background: "rgba(0, 0, 0, 0.1)", // More transparent black background
                            borderRadius: "15px",
                            padding: "30px",
                            textAlign: "center",
                            transition: "transform 0.3s, box-shadow 0.3s",
                            cursor: "pointer",
                            color: "#fff", // White text for better visibility
                            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.5)", // Slightly darker shadow for modern look
                          }}
                          onMouseOver={(e) => {
                            e.currentTarget.style.transform = "scale(1.05)";
                            e.currentTarget.style.boxShadow =
                              "0 8px 30px rgba(0, 0, 0, 0.7)"; // Deeper shadow on hover
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.style.transform = "scale(1)";
                            e.currentTarget.style.boxShadow =
                              "0 4px 20px rgba(0, 0, 0, 0.5)"; // Reset shadow on mouse out
                          }}
                        >
                          <div
                            style={{
                              background: "#fff", // White background for the icon circle
                              borderRadius: "50%",
                              width: "90px",
                              height: "90px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              margin: "0 auto 15px auto",
                            }}
                          >
                            <i
                              className={service.icon}
                              style={{
                                fontSize: "40px",
                                color: "#1b1b1b", // Dark color for icon
                              }}
                            ></i>
                          </div>
                          <h5 style={{ color: "#fff", fontSize: "1.5em" }}>
                            {service.title}
                          </h5>
                          <p style={{ color: "#fff", fontSize: "1em" }}>
                            {service.description}
                          </p>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
