import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
import headerImg from "../assets/img/earth.png";
import { useState, useEffect } from "react";

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showCursor, setShowCursor] = useState(true);
  const [toRotate] = useState([
    "Web Developer",
    "Freelancer",
    "UI/UX Designer",
  ]);

  const [text, setText] = useState("");
  const typingSpeed = 100; // Slower typing speed
  const deletingSpeed = 50; // Slower deleting speed
  const [delta, setDelta] = useState(typingSpeed);
  const period = 1000; // Pause between deleting and typing

  useEffect(() => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];

    if (!isDeleting && text === fullText) {
      setTimeout(() => {
        setIsDeleting(true);
        setDelta(deletingSpeed);
      }, period);
    } else if (isDeleting && text === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(typingSpeed);
    }
  }, [text, isDeleting]); // Added isDeleting here

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);
  };

  useEffect(() => {
    let timer = setTimeout(tick, delta);
    return () => clearTimeout(timer);
  }, [text, isDeleting, delta]); // Added isDeleting and delta here

  useEffect(() => {
    const timer = setInterval(() => {
      setShowCursor((prevShowCursor) => !prevShowCursor);
    }, 500);
    return () => clearInterval(timer);
  }, []);

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <span className="tagline">Welcome to my e-Portfolio</span>
            <h1>
              I am a{" "}
              <div className="responsive-wrapper">
                <span className="wrap">
                  {text}
                  <span className={`cursor ${showCursor ? "" : "hidden"}`}>
                    |
                  </span>
                </span>
              </div>
            </h1>

            <p className="sub-heading">
              I do freelancing in my free time and I am open to new projects.
              Feel free to click the button below to use my service.
            </p>
            <button
              onClick={() => window.open("https://wa.me/601111495803")}
              className="btn"
            >
              Use My Service <ArrowRightCircle size={25} />
            </button>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <img
              src={headerImg}
              alt="Hazeeq Haikal"
              style={{ maxHeight: "400px", width: "auto" }}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
