import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../assets/img/logo.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import { HashLink } from "react-router-hash-link";
import { BrowserRouter as Router } from "react-router-dom";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      const offset = 10; // Adjust this value as needed
      const scrollPos = window.scrollY + offset;
      const homePos = document.getElementById("home").offsetTop;
      const skillsPos = document.getElementById("skills").offsetTop;
      const servicesPos = document.getElementById("services").offsetTop;
      const projectsPos = document.getElementById("projects").offsetTop;
      const contactPos = document.getElementById("connect").offsetTop;

      if (scrollPos < skillsPos) {
        setActiveLink("home");
      } else if (scrollPos < servicesPos) {
        setActiveLink("skills");
      } else if (scrollPos < projectsPos) {
        setActiveLink("services");
      } else if (scrollPos < contactPos) {
        setActiveLink("projects");
      } else {
        setActiveLink("connect");
      }

      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              alt="Logo"
              style={{
                maxWidth: "180px", // Adjusted for default view
                width: "100%",
              }}
              className="logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                href="#home"
                className={
                  activeLink === "home" ? "active navbar-link" : "navbar-link"
                }
                onClick={(event) => {
                  onUpdateActiveLink("home");
                  event.currentTarget.blur();
                }}
                style={{ fontSize: "1.2rem", marginTop: "10px" }}
              >
                Home
              </Nav.Link>
              <Nav.Link
                href="#skills"
                className={
                  activeLink === "skills" ? "active navbar-link" : "navbar-link"
                }
                onClick={(event) => {
                  onUpdateActiveLink("skills");
                  event.currentTarget.blur();
                }}
                style={{ fontSize: "1.2rem", marginTop: "10px" }}
              >
                Skills
              </Nav.Link>
              <Nav.Link
                href="#services"
                className={
                  activeLink === "services"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={(event) => {
                  onUpdateActiveLink("services");
                  event.currentTarget.blur();
                }}
                style={{ fontSize: "1.2rem", marginTop: "10px" }}
              >
                Services
              </Nav.Link>
              <Nav.Link
                href="#projects"
                className={
                  activeLink === "projects"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={(event) => {
                  onUpdateActiveLink("projects");
                  event.currentTarget.blur();
                }}
                style={{ fontSize: "1.2rem", marginTop: "10px" }}
              >
                Projects
              </Nav.Link>
              <Nav.Link
                href="#connect"
                className={
                  activeLink === "connect"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={(event) => {
                  onUpdateActiveLink("connect");
                  event.currentTarget.blur();
                }}
                style={{ fontSize: "1.2rem", marginTop: "10px" }}
              >
                Contact
              </Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a
                  href="https://www.linkedin.com/in/muhammad-hazeeq-haikal-149449246/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={navIcon1} alt="" />
                </a>
                <a
                  href="https://github.com/HazeeqHaikal"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={navIcon2} alt="" />
                </a>
              </div>
              <a
                href="https://wa.me/+601111495803"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  className="vvd"
                  style={{ padding: "10px 15px", fontSize: "1rem" }}
                >
                  <span>Use My Service</span>
                </button>
              </a>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Inline styles */}
      <style jsx>{`
        @media (max-width: 768px) {
          .logo {
            max-width: 200px; /* Increase logo size specifically for mobile */
          }
          .navbar-link {
            font-size: 1.2rem;
            margin-top: 10px;
          }
          .navbar-text .vvd {
            padding: 10px 15px;
            font-size: 1rem;
          }
        }
      `}</style>
    </Router>
  );
};
