import logo from "./logo.svg";
import "./App.css";
import { NavBar } from "./component/NavBar";
import { Banner } from "./component/Banner";
import { Skills } from "./component/Skills";
import { Projects } from "./component/Projects";
import { Contact } from "./component/Contact";
import { Footer } from "./component/Footer";
import { Services } from "./component/Services";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

function App() {
  useEffect(() => {
    document.title = "Hazeeq Haikal's Portfolio";
  }, []);

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hazeeq Haikal's Portfolio</title>
        <link rel="canonical" href="http://hazeeq.org" />
        <meta name="description" content="This is Hazeeq Haikal's Portfolio" />
      </Helmet>
      <NavBar />
      <Banner />
      <Skills />
      <Services />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
